import React, { useEffect, useState } from "react";

import { Delete, Warning } from "@mui/icons-material";
import { Alert, Avatar, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogContent, Divider, Grid, Rating, Stack, Typography } from "@mui/material";
import axios from "axios";

export default function ReviewAdmin({ snackbar }) {
	const [open, setOpen] = useState("");
	const [action, setAction] = useState("");

	const apiUrl = process.env.REACT_APP_ADMIN_V2;

	useEffect(() => {
		get_review();
	}, []);

	const [review, setReview] = useState([]);
	const get_review = () => {
		axios.get(`${apiUrl}?action=get-reviews`).then(function (res) {
			if (res.data.status === true) {
				setReview(res.data.data);
			} else {
				setReview(res.data);
			}
		});
	};

	const [reviewId, setReviewId] = useState("");
	const open_delete = (reviewId) => {
		setReviewId(reviewId);
		setOpen("delete");
	};

	const delete_review = () => {
		setAction("delete");
		let formData = new FormData();
		formData.append("reviewId", reviewId);
		axios.post(`${apiUrl}?action=delete-review`, formData).then(function (res) {
			setAction("");
			snackbar(res.data.msg);
			if (res.data.status === true) {
				setOpen("");
				get_review();
			}
		});
	};

	return (
		<Box className="content" pt={3}>
			<Dialog open={open === "delete" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpen("")}>
				<DialogContent>
					<Box textAlign={"center"}>
						<Warning sx={{ width: 100, height: 100 }} color="error" />
						<Typography color="error" fontSize={20} fontWeight={600}>
							Are you sure to delete this review?
						</Typography>
						<Typography color="grey" fontSize={12}>
							*You can not undo this action
						</Typography>
					</Box>
					<Stack mt={2} spacing={1}>
						<Button variant="contained" onClick={() => delete_review()} color="error" disabled={action === "delete" ? true : false}>
							{action === "delete" ? <CircularProgress size={20} /> : "Yes, Delete"}
						</Button>
						<Button variant="outlined" color="info" onClick={() => setOpen("")}>
							Cancel
						</Button>
					</Stack>
				</DialogContent>
			</Dialog>

			<Container>
				{review.status === false ? (
					<Alert severity="info">{review.msg}</Alert>
				) : (
					<Grid container spacing={2} pb={3}>
						{review.map((row, i) => (
							<Grid item xs={12} md={4} key={i}>
								<Card>
									<CardContent>
										<Box mb={1} textAlign={"center"}>
											<Avatar sx={{ width: 100, height: 100, mx: "auto" }} src={`/spywareassets/student/${row.studentid}.jpg`} />

											<Typography variant="h5" fontWeight={"bold"} color={"info.main"}>
												{row.name}
											</Typography>
											<Typography mb={1} variant="body2" color={"gray"}>
												{new Date(row.created_at).toLocaleDateString(undefined, { day: "2-digit", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit", hour12: true })}
											</Typography>

											<Rating name="simple-controlled" value={row.rating} readOnly />

											<Typography mt={1} variant="body2" color={"gray"}>
												{row.review}
											</Typography>
										</Box>
										<Divider sx={{ color: "gray", fontSize: 12 }}>ACTION</Divider>
										<Box mt={1}>
											<Button fullWidth variant="contained" color="error" startIcon={<Delete />} onClick={() => open_delete(row.review_id)}>
												Delete Review
											</Button>
										</Box>
									</CardContent>
								</Card>
							</Grid>
						))}
					</Grid>
				)}
			</Container>
		</Box>
	);
}

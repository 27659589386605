import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

/* MUI */
import { AccountBalanceWallet, Attribution, CircleNotifications, CoPresent, FilterVintage, Menu, Message, PowerSettingsNew, Reviews, ViewTimeline } from "@mui/icons-material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BookIcon from "@mui/icons-material/Book";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { AppBar, Box, Button, CircularProgress, Dialog, DialogContent, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material";

export default function SidebarController({ apiglobal }) {
	let navi = useNavigate();

	const location = useLocation();

	const auth = Cookies.get("authuser");
	useEffect(() => {
		if (auth === undefined) {
			navi("/");
		}
	}, [auth]);

	const [frname, setFrname] = useState("");
	const checkfrname = Cookies.get("frname");
	useEffect(() => {
		if (checkfrname !== undefined) {
			setFrname(checkfrname);
		} else {
			setFrname("CONTROLLER");
		}
	});
	const [open, setOpen] = useState(false);

	const links =
		auth === "admin"
			? [
					{ name: "Dashboard", link: `/controller/dashboard`, icon: <DashboardIcon /> },
					{ name: "Franchise", link: "/controller/franchises", icon: <AccountTreeIcon /> },
					{ name: "Course", link: "/controller/courses", icon: <BookIcon /> },
					{ name: "Student", link: "/controller/students", icon: <GroupIcon /> },
					{ name: "Gallery", link: "/controller/gallery", icon: <InsertPhotoIcon /> },
					{ name: "Teacher", link: "/controller/teacher", icon: <Attribution /> },
					{ name: "Review", link: "/controller/review", icon: <Reviews /> },
					{ name: "Notification", link: "/controller/notification", icon: <CircleNotifications /> },
					{ name: "Message", link: "/controller/message", icon: <Message /> },
					{ name: "Hero Slider", link: "/controller/hero-slider", icon: <FilterVintage /> },
			  ]
			: [
					{ name: "Dashboard", link: "/controller/dashboard-franchise", icon: <DashboardIcon /> },
					{ name: "Student", link: "/controller/students", icon: <GroupIcon /> },
					{ name: "Batch", link: "/controller/batch", icon: <ViewTimeline /> },
					{ name: "Attendance", link: "/controller/attendance", icon: <CoPresent /> },
					{ name: "Payment", link: "/controller/payment", icon: <AccountBalanceWallet /> },
			  ];

	const [openLogout, setOpenLogout] = useState(false);

	const [working, setWorking] = useState(false);
	const logout = () => {
		setWorking(true);
		var formData = new FormData();
		formData.append("logout", "");
		fetch(apiglobal, {
			method: "post",
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				setWorking(false);
				if (data.status === 200) {
					navi("/");
				}
			});
	};

	return (
		<Box component="nav" sx={{ marginBottom: 10 }}>
			<Drawer variant="permanent" PaperProps={{ style: { width: "256px", background: "secondary.main" } }} sx={{ display: { xs: "none", md: "block" } }}>
				<Box pt={2.1} pb={2.1} sx={{ backgroundColor: "primary.main" }}>
					<Typography fontSize={20} letterSpacing={2} className="cert-font" color="white" fontWeight="bold" noWrap align="center">
						{frname}
					</Typography>
				</Box>
				<Divider />
				<List>
					{links.map((data, i) => (
						<ListItemButton key={i} onClick={() => navi(data.link)} sx={location.pathname === data.link ? { backgroundColor: "secondary.main", color: "black" } : { backgroundColor: "transparent" }}>
							<ListItem>
								<ListItemIcon>{data.icon}</ListItemIcon>
								<ListItemText>{data.name}</ListItemText>
							</ListItem>
						</ListItemButton>
					))}
				</List>
			</Drawer>
			<Drawer open={open} anchor="bottom" onClose={() => setOpen(false)} PaperProps={{ style: { borderTopRightRadius: 25, borderTopLeftRadius: 25 } }}>
				<List>
					{links.map((data, i) => (
						<NavLink key={i} to={data.link} onClick={() => setOpen(false)} style={{ color: "black" }}>
							<ListItem>
								<ListItemButton>
									<ListItemIcon>{data.icon}</ListItemIcon>
									<ListItemText>{data.name}</ListItemText>
								</ListItemButton>
							</ListItem>
						</NavLink>
					))}
				</List>
			</Drawer>
			<AppBar color="primary" position="fixed">
				<Toolbar>
					<Typography noWrap fontSize={20} fontWeight="bold" letterSpacing={2} color={"white"} fontFamily="serif" sx={{ flexGrow: 1, display: { xs: "block", md: "none" } }} className="cert-font">
						{frname}
					</Typography>
					<Typography sx={{ flexGrow: 1, xs: "none", md: "block" }}></Typography>
					<IconButton color="error" onClick={() => setOpenLogout(true)}>
						<PowerSettingsNew />
					</IconButton>
					<Box sx={{ display: { xs: "block", md: "none" } }}>
						<IconButton color="error" edge="end" onClick={() => setOpen(true)}>
							<Menu />
						</IconButton>
					</Box>
				</Toolbar>
			</AppBar>
			<Outlet />

			<Dialog open={openLogout} fullWidth maxWidth="xs" onClose={() => setOpenLogout(false)}>
				<DialogContent>
					<Box mt={2} mb={2}>
						<Typography fontWeight="bold" fontSize={25}>
							Do you want to logout?
						</Typography>
						<Typography variant="caption">Note: This process will redirect you to the login page.</Typography>

						<Box textAlign="center" mt={2}>
							<Button color="primary" variant="contained" sx={{ borderRadius: 0, boxShadow: "none" }} disabled={working} onClick={() => logout()}>
								{working ? <CircularProgress color="inherit" size={24} /> : "YES LOGOUT"}
							</Button>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</Box>
	);
}

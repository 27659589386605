import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

/* MUI */
import { CloudUpload, Person, School, WorkspacePremium } from "@mui/icons-material";
import { Alert, Box, Button, Card, CardContent, CircularProgress, Container, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";

export default function DashboardController({ apiadmin, snackbar }) {
	const currentDate = new Date();
	const year = currentDate.getFullYear();
	const month = String(currentDate.getMonth() + 1).padStart(2, "0");
	const day = String(currentDate.getDate()).padStart(2, "0");
	const formattedDate = `${year}-${month}-${day}`;
	const apiUrl = process.env.REACT_APP_ADMIN_V2;

	const [action, setAction] = useState("");

	const [filterday, setFilterday] = useState(formattedDate);
	useEffect(() => {
		dashboard(filterday);
	}, [filterday]);

	const [dash, setDash] = useState([]);
	const [student, setStudent] = useState([]);
	const dashboard = (filterday) => {
		var formData = new FormData();
		formData.append("dashboard", "");
		formData.append("day", filterday);
		axios.post(apiadmin, formData).then(function (res) {
			setDash(res.data.dash.data);
			if (res.data.student.status === false) {
				setStudent(res.data.student);
			} else {
				setStudent(res.data.student.data);
			}
		});
	};

	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "absolute",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	});

	const change_banner = (img) => {
		setAction("banner");
		const formData = new FormData();
		formData.append("img", img.target.files[0]);
		axios.post(`${apiUrl}?action=add-banner`, formData).then(function (res) {
			setAction("");
			snackbar(res.data.msg);
			if (res.data.status === true) {
				window.location.reload();
			}
		});
	};

	const change_profile = (img) => {
		setAction("profile");
		const formData = new FormData();
		formData.append("img", img.target.files[0]);
		axios.post(`${apiUrl}?action=change-admin-profile`, formData).then(function (res) {
			setAction("");
			snackbar(res.data.msg);
			if (res.data.status === true) {
				window.location.reload();
			}
		});
	};

	return (
		<Box className="content" pb={10}>
			<Container>
				<Grid container spacing={2} mt={3}>
					<Grid item xs={6} md={4}>
						<Box padding={2} component={Paper} textAlign="center">
							<Person sx={{ fontSize: 70, color: "#FF4F00" }} />
							<Box sx={{ padding: 0.1, background: "red" }} />

							<Stack mt={1} direction="row" justifyContent="space-between" alignItems="center">
								<Typography color="gray" fontSize={12} letterSpacing={1}>
									STUDENTS
								</Typography>
								<Typography color="red" fontWeight="bold" fontSize={20} letterSpacing={3}>
									{dash.active_student}
								</Typography>
							</Stack>
						</Box>
					</Grid>
					<Grid item xs={6} md={4}>
						<Box padding={2} component={Paper} textAlign="center">
							<WorkspacePremium sx={{ fontSize: 70, color: "#007FFF" }} />
							<Box sx={{ padding: 0.1, background: "red" }} />

							<Stack mt={1} direction="row" justifyContent="space-between" alignItems="center">
								<Typography color="gray" fontSize={12} letterSpacing={1}>
									ISSUED
								</Typography>
								<Typography color="red" fontWeight="bold" fontSize={20} letterSpacing={3}>
									{dash.issued_certificate}
								</Typography>
							</Stack>
						</Box>
					</Grid>
					<Grid item xs={12} md={4}>
						<Box padding={2} component={Paper} textAlign="center">
							<School sx={{ fontSize: 70, color: "#03C03C" }} />
							<Box sx={{ padding: 0.1, background: "red" }} />

							<Stack mt={1} direction="row" justifyContent="space-between" alignItems="center">
								<Typography color="gray" fontSize={12} letterSpacing={1}>
									FRANCHISE
								</Typography>
								<Typography color="red" fontWeight="bold" fontSize={20} letterSpacing={3}>
									{dash.franchise}
								</Typography>
							</Stack>
						</Box>
					</Grid>
				</Grid>
				<Grid container spacing={2} mt={0}>
					<Grid item xs={12} md={12}>
						<Box textAlign={"center"} mb={2}>
							<NavLink to={"/controller/workshop"}>Workshop data</NavLink>
						</Box>
						<Box component={Paper} textAlign="center" height={450}>
							<Stack p={1.5} direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ bgcolor: "#F5F5F5" }}>
								<Typography>New students</Typography>
								<TextField type="date" size="small" value={filterday} onChange={(event) => setFilterday(event.target.value)} />
							</Stack>
							<Box p={1.5}>
								<TableContainer sx={{ maxHeight: 350 }}>
									{student.status === false ? (
										<Alert severity="warning" sx={{ mt: 2 }}>
											{student.msg}
										</Alert>
									) : (
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>#</TableCell>
													<TableCell align="left">Name</TableCell>
													<TableCell align="left">Guardian</TableCell>
													<TableCell align="left">Mobile</TableCell>
													<TableCell align="left">Gender</TableCell>
													<TableCell align="right">Join date</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{student.map((row, i) => (
													<TableRow key={i}>
														<TableCell align="left">{i + 1}</TableCell>
														<TableCell align="left">
															<NavLink style={{ color: "darkorange" }} to={`/controller/student-info/${row.studentid}`}>
																{row.name}
															</NavLink>
														</TableCell>
														<TableCell align="left">{row.guardian}</TableCell>
														<TableCell align="left">
															<NavLink style={{ color: "blue" }} to={`tel: ${row.mobile}`}>
																{row.mobile}
															</NavLink>
														</TableCell>
														<TableCell align="left">{row.gender}</TableCell>
														<TableCell align="right">{row.joindate}</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									)}
								</TableContainer>
							</Box>
						</Box>
					</Grid>
				</Grid>

				<Grid container mt={2} spacing={2}>
					<Grid item xs={12} md={6}>
						<Card>
							<CardContent>
								<Button component="label" fullWidth color="info" role={undefined} variant="contained" tabIndex={-1} startIcon={action === "profile" ? <CircularProgress size={24} /> : <CloudUpload />} disabled={action === "profile" ? true : false} onChange={(event) => change_profile(event)}>
									Upload Admin Profile
									<VisuallyHiddenInput type="file" onChange={(event) => console.log(event.target.files)} multiple />
								</Button>
								<img src={`/spywareassets/gallery/admin_profile.jpg`} alt="Admin profile" style={{ width: "100%", borderRadius: 10, marginTop: 10 }} />
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

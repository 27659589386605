import React, { useEffect, useState } from "react";

import { Upload } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, TextField } from "@mui/material";
import axios from "axios";

export default function MessageAdmin({ snackbar }) {
	const [action, setAction] = useState("");
	const apiUrl = process.env.REACT_APP_ADMIN_V2;

	const [message, setMessage] = useState("");
	const [messageId, setMessageId] = useState("");

	useEffect(() => {
		axios.get(`${apiUrl}?action=get-message`).then(function (res) {
			if (res.data.status === true) {
				setMessage(res.data.data.message);
				setMessageId(res.data.data.message_id);
			}
		});
	}, []);

	const save = () => {
		setAction("update");
		let formData = new FormData();
		formData.append("messageId", messageId);
		formData.append("message", message);
		axios.post(`${apiUrl}?action=update-message`, formData).then(function (res) {
			setAction("");
			snackbar(res.data.msg);
		});
	};

	return (
		<Box className="content">
			<Container>
				<Box pt={1}>
					<TextField fullWidth label="Director Message" multiline rows={4} value={message} onChange={(e) => setMessage(e.target.value)} />

					<Box mt={2}>
						<Button variant="contained" fullWidth color="info" size="large" startIcon={action === "update" ? <CircularProgress size={20} color="inherit" /> : <Upload />} onClick={() => save()} disabled={action === "update" ? true : false}>
							Upload
						</Button>
					</Box>
				</Box>
			</Container>
		</Box>
	);
}

import React, { useEffect, useState } from "react";
import {} from "react-router-dom";
import Footer from "./footer";

// MUI
import {} from "@mui/icons-material";
import { Box, Container, Grid } from "@mui/material";
import axios from "axios";

export default function CoursesList() {
	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	const apiUrl = process.env.REACT_APP_PUBLIC_V2;

	const [content, setContent] = useState("");
	useEffect(() => {
		axios.post(`${apiUrl}?action=website-course`).then(function (res) {
			if (res.data.status === true) {
				setContent(res.data.data.content);
			}
		});
	}, []);

	return (
		<Box sx={{ backgroundColor: "primary.main", color: "white" }} pt={3}>
			<Container>
				<Grid container spacing={2} pb={3}>
					<Grid item xs={12} md={10} mx={"auto"}>
						<Box dangerouslySetInnerHTML={{ __html: content }} />
					</Grid>
				</Grid>
			</Container>
			<Footer />
		</Box>
	);
}

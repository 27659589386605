import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// MUI
import { Add, Book, Edit } from "@mui/icons-material";
import { Alert, Box, Button, Card, CardContent, Container, Divider, Grid, IconButton, LinearProgress, Stack, Typography } from "@mui/material";

export default function Courses({ apiadmin }) {
	let navi = useNavigate();

	useEffect(() => {
		getCourse();
	}, []);

	const [loading, setLoading] = useState(false);
	const [courses, setCourses] = useState([]);
	const getCourse = () => {
		setLoading(true);
		var formData = new FormData();
		formData.append("get-courses", "");
		fetch(apiadmin, {
			method: "post",
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				setLoading(false);
				if (data.status == false) {
					setCourses(data);
				} else {
					setCourses(data.data);
				}
			});
	};

	return (
		<Box className="content">
			<Container>
				<Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
					<Typography fontSize={14} fontWeight="bold">
						Courses
					</Typography>
					<Stack spacing={1} direction={"row"}>
						<Button startIcon={<Add />} variant="outlined" onClick={() => navi("/controller/website-course")}>
							Website Course
						</Button>
						<Button startIcon={<Add />} variant="outlined" onClick={() => navi("/controller/course-upload/0")}>
							Add Course
						</Button>
					</Stack>
				</Stack>
				{loading ? (
					<LinearProgress color="primary" />
				) : courses.status == false ? (
					<Alert severity="warning">{courses.message}</Alert>
				) : (
					<Grid container spacing={2}>
						{courses.map((data, i) => (
							<Grid item xs={12} md={4} key={i}>
								<Card>
									<CardContent>
										<Stack direction="row" justifyContent="space-between" alignItems="center">
											<Typography fontSize={30} fontWeight="bold" letterSpacing={2}>
												{data.c_title}
											</Typography>
											<Box>
												<IconButton sx={{ background: "#F5F5F5" }} onClick={() => navi(`/controller/course-upload/${data.courseid}`)}>
													<Edit />
												</IconButton>
												{data.c_title !== "TYPING" ? (
													<IconButton sx={{ background: "#F5F5F5", ml: 0.5 }} onClick={() => navi(`/controller/course-subject/${data.courseid}`)}>
														<Book />
													</IconButton>
												) : (
													""
												)}
											</Box>
										</Stack>
										<Typography fontSize={14} color="gray" noWrap mb={2}>
											{data.c_name}
										</Typography>
										<Divider />
										<Stack direction="row" justifyContent="space-between" alignItems="center" mt={2}>
											<Typography fontSize={14} color="gray">
												Duration
											</Typography>
											<Typography fontWeight="bold" fontSize={16}>
												{data.c_duration} Months
											</Typography>
										</Stack>
										<Stack direction="row" justifyContent="space-between" alignItems="center" mt={2}>
											<Typography fontSize={14} color="gray">
												Status
											</Typography>
											<Typography fontWeight="bold" fontSize={16}>
												{data.c_status}
											</Typography>
										</Stack>
									</CardContent>
								</Card>
							</Grid>
						))}
					</Grid>
				)}
			</Container>
		</Box>
	);
}

import { Delete, Upload } from "@mui/icons-material";
import { Box, Chip, CircularProgress, Container, Stack } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";

export default function NotificationAdmin({ snackbar }) {
	const [action, setAction] = useState(false);
	const apiUrl = process.env.REACT_APP_ADMIN_V2;

	const modules = {
		toolbar: [
			[{ header: [1, 2, 3, false] }],
			["bold", "italic", "underline", "strike"],
			[{ script: "sub" }, { script: "super" }],
			[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
			[{ indent: "-1" }, { indent: "+1" }],
			[{ align: [] }],
			["blockquote", "code-block"],
			["link", "video", "formula"],
			[{ color: [] }, { background: [] }],
			[{ font: [] }],
			[{ size: [] }],
			["clean"], // Removes formatting
		],
	};

	const formats = ["header", "bold", "italic", "underline", "strike", "script", "list", "bullet", "check", "indent", "align", "blockquote", "code-block", "link", "image", "video", "formula", "color", "background", "font", "size"];

	const [value, setValue] = useState("");
	const [notificationId, setNotificationId] = useState("");

	useEffect(() => {
		get_notification();
	}, []);
	const get_notification = () => {
		axios.get(`${apiUrl}?action=get-notifications`).then(function (res) {
			if (res.data.status === true) {
				setValue(res.data.data.notification);
				setNotificationId(res.data.data.notification_id);
			}
		});
	};

	const save = () => {
		setAction("saving");
		let formData = new FormData();
		formData.append("notificationId", notificationId);
		formData.append("notification", value);
		axios.post(`${apiUrl}?action=post-notification`, formData).then(function (res) {
			setAction("");
			snackbar(res.data.msg);
		});
	};

	const delete_notification = () => {
		setAction("deleting");
		var formData = new FormData();
		formData.append("notificationId", notificationId);
		axios.post(`${apiUrl}?action=delete-notification`, formData).then(function (res) {
			setAction("");
			snackbar(res.data.msg);
			res.data.status === true && setValue("");
		});
	};

	return (
		<Box className="content">
			<Container>
				<Box pt={2}>
					<Stack mb={2} spacing={1} direction={"row"}>
						<Chip label="Delete Notification" color="error" icon={action === "deleting" ? <CircularProgress size={20} color="inherit" /> : <Delete />} onClick={() => delete_notification()} disabled={action === "deleting" ? true : false} />
						<Chip label="Post Notification" color="info" icon={action === "saving" ? <CircularProgress size={20} color="inherit" /> : <Upload />} onClick={() => save()} disabled={action === "saving" ? true : false} />
					</Stack>

					<Box>
						<ReactQuill theme="snow" value={value} onChange={setValue} modules={modules} formats={formats} />
					</Box>
				</Box>
			</Container>
		</Box>
	);
}

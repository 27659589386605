import { CameraAlt, Delete, Edit, Upload, Warning } from "@mui/icons-material";
import { Alert, Avatar, Badge, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogContent, Divider, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function TeacherAdmin({ snackbar }) {
	const apiUrl = process.env.REACT_APP_ADMIN_V2;
	const [action, setAction] = useState("");
	const [open, setOpen] = useState("");

	const [teachers, setTeachers] = useState([]);
	useEffect(() => {
		get_teachers();
	}, []);

	const get_teachers = () => {
		axios.post(`${apiUrl}?action=get-teachers`).then(function (res) {
			if (res.data.status === true) {
				setTeachers(res.data.data);
			} else {
				setTeachers(res.data);
			}
		});
	};

	const [teacherId, setTeacherId] = useState("");
	const [name, setName] = useState("");
	const [experienceYear, setExperienceYear] = useState("");
	const [description, setDescription] = useState("");
	const add_teacher = () => {
		setAction("add");
		let formData = new FormData();
		formData.append("teacherId", teacherId);
		formData.append("name", name);
		formData.append("experienceYear", experienceYear);
		formData.append("description", description);
		axios.post(`${apiUrl}?action=add-teacher`, formData).then(function (res) {
			setAction("");
			snackbar(res.data.msg);
			if (res.data.status === true) {
				setTeacherId("");
				setName("");
				setExperienceYear("");
				setDescription("");
				get_teachers();
			}
		});
	};

	const get_teacher_details = (teacherId) => {
		let formData = new FormData();
		formData.append("teacherId", teacherId);
		axios.post(`${apiUrl}?action=teacher-details`, formData).then(function (res) {
			if (res.data.status === true) {
				window.scroll(0, 0);
				setTeacherId(teacherId);
				setName(res.data.data.name);
				setExperienceYear(res.data.data.experience_year);
				setDescription(res.data.data.description);
			}
		});
	};

	const delete_teacher = () => {
		setAction("delete");
		let formData = new FormData();
		formData.append("teacherId", teacherId);
		axios.post(`${apiUrl}?action=delete-teacher`, formData).then(function (res) {
			setAction("");
			snackbar(res.data.msg);
			if (res.data.status === true) {
				setOpen("");
				get_teachers();
			}
		});
	};

	const open_delete = (teacherId) => {
		setTeacherId(teacherId);
		setOpen("delete");
	};

	const add_teacher_img = (teacherId, event) => {
		setAction("uploading");
		let formData = new FormData();
		formData.append("teacherId", teacherId);
		formData.append("img", event.target.files[0]);
		axios.post(`${apiUrl}?action=add-teacher-img`, formData).then(function (res) {
			setAction("");
			snackbar(res.data.msg);
			if (res.data.status === true) {
				get_teachers();
			}
		});
	};

	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "absolute",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	});

	return (
		<Box className="content" pt={1}>
			<Container>
				<Dialog open={open === "delete" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpen("")}>
					<DialogContent>
						<Box textAlign={"center"}>
							<Warning sx={{ width: 100, height: 100 }} color="error" />
							<Typography color="error" fontSize={20} fontWeight={600}>
								Are you sure to delete this teacher?
							</Typography>
							<Typography color="grey" fontSize={12}>
								*You can not undo this action
							</Typography>
						</Box>
						<Stack mt={2} spacing={1}>
							<Button variant="contained" onClick={() => delete_teacher()} color="error" disabled={action === "delete" ? true : false}>
								{action === "delete" ? <CircularProgress size={20} /> : "Yes, Delete"}
							</Button>
							<Button variant="outlined" color="info" onClick={() => setOpen("")}>
								Cancel
							</Button>
						</Stack>
					</DialogContent>
				</Dialog>

				<Stack mb={2} direction={"row"} spacing={2}>
					<TextField fullWidth type="text" variant="outlined" label="Teacher name" size="small" value={name} onChange={(event) => setName(event.target.value)} />
					<TextField fullWidth type="number" variant="outlined" label="Experience year" size="small" value={experienceYear} onChange={(event) => setExperienceYear(event.target.value)} />
				</Stack>
				<TextField fullWidth variant="outlined" label="About teacher" size="small" rows={4} multiline value={description} onChange={(event) => setDescription(event.target.value)} />
				<Box mt={2}>
					<Button variant="contained" color="info" startIcon={<Upload />} sx={{ width: "100%" }} disabled={action === "add" ? true : false} onClick={() => add_teacher()}>
						{action === "add" ? <CircularProgress size={20} /> : "Upload teacher"}
					</Button>
				</Box>

				<Box mt={4}>
					{teachers.status === false ? (
						<Alert severity="info">No teacher found</Alert>
					) : (
						<Grid container spacing={2}>
							{teachers.map((row, i) => (
								<Grid item xs={12} md={6} key={i}>
									<Card>
										<CardContent>
											<Stack direction={"row"} alignItems={"center"} spacing={2}>
												<Badge
													overlap="circular"
													anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
													badgeContent={
														<IconButton component="label">
															<CameraAlt /> <VisuallyHiddenInput onChange={(event) => add_teacher_img(row.teacher_id, event)} type="file" accept=".jpeg, .jpg, .png" />
														</IconButton>
													}
												>
													<Avatar sx={{ width: 90, height: 90 }} src={`../spywareassets/gallery/${row.teacher_id}.jpg`} />
												</Badge>
												<Box>
													<Typography fontSize={16} fontWeight={600}>
														{row.name}
													</Typography>
													<Typography color={"gray"} fontSize={12}>
														{row.experience_year} Years of experience
													</Typography>
												</Box>
											</Stack>
											<Typography mb={2} fontSize={14} mt={2} color={"gray"}>
												{row.description}
											</Typography>

											<Divider sx={{ fontSize: 12 }}>ACTIONS</Divider>
											<Stack mt={2} spacing={1} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
												<Button variant="contained" sx={{ width: "100%" }} onClick={() => open_delete(row.teacher_id)} color="error" startIcon={<Delete />}>
													Delete
												</Button>
												<Button variant="contained" sx={{ width: "100%" }} onClick={() => get_teacher_details(row.teacher_id)} color="info" startIcon={<Edit />}>
													Edit
												</Button>
											</Stack>
										</CardContent>
									</Card>
								</Grid>
							))}
						</Grid>
					)}
				</Box>
			</Container>
		</Box>
	);
}

import React, { useEffect, useState } from "react";

import { Box, Chip, CircularProgress, Container } from "@mui/material";

// REACT QUILL
import { Save } from "@mui/icons-material";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function CourseContent({ snackbar }) {
	const [value, setValue] = useState("");
	const [saving, setSaving] = useState(false);
	const apiUrl = process.env.REACT_APP_ADMIN_V2;
	const [courseWebsiteId, setCourseWebsiteId] = useState("");

	useEffect(() => {
		axios.post(`${apiUrl}?action=get-website-course`).then(function (res) {
			if (res.data.status === true) {
				setValue(res.data.data.content);
				setCourseWebsiteId(res.data.data.course_website_id);
			}
		});
	}, []);

	const save = () => {
		setSaving(true);
		let data = new FormData();
		data.append("courseWebsiteId", courseWebsiteId);
		data.append("content", value);
		axios.post(`${apiUrl}?action=update-course-content`, data).then(function (res) {
			setSaving(false);
			snackbar(res.data.msg);
		});
	};

	const modules = {
		toolbar: [
			[{ header: [1, 2, 3, false] }],
			["bold", "italic", "underline", "strike"],
			[{ script: "sub" }, { script: "super" }],
			[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
			[{ indent: "-1" }, { indent: "+1" }],
			[{ align: [] }],
			["blockquote", "code-block"],
			["link", "video", "formula"],
			[{ color: [] }, { background: [] }],
			[{ font: [] }],
			[{ size: [] }],
			["clean"], // Removes formatting
		],
	};

	const formats = ["header", "bold", "italic", "underline", "strike", "script", "list", "bullet", "check", "indent", "align", "blockquote", "code-block", "link", "image", "video", "formula", "color", "background", "font", "size"];

	return (
		<Box className="content" sx={{ zIndex: 1 }}>
			<Container>
				<Box mb={1}>
					<Chip color="success" label="Save" icon={saving === true ? <CircularProgress size={20} /> : <Save />} clickable disabled={saving === true ? true : false} onClick={() => save()} />
				</Box>
				<Box>
					<ReactQuill theme="snow" value={value} onChange={setValue} modules={modules} formats={formats} />
				</Box>
			</Container>
		</Box>
	);
}

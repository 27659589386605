import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// MUI
import {} from "@mui/icons-material";
import { Box, Button, Container, Grid, Paper, TextField, Typography } from "@mui/material";

export default function AdminLogin({ snackbar, apiadminlogin }) {
	const navi = useNavigate();
	const auth = Cookies.get("authuser");
	useEffect(() => {
		if (auth === "admin") {
			navi("/controller/dashboard", { replace: true });
		}
	}, [auth]);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const login = () => {
		var formData = new FormData();
		formData.append("login-admin", email);
		formData.append("password", password);
		fetch(apiadminlogin, {
			method: "post",
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.status == false) {
					snackbar(data.message);
				} else {
					navi("/controller/dashboard", { replace: true });
				}
			});
	};

	return (
		<Box>
			<Container>
				<Grid container>
					<Grid item xs={11} md={5} mx="auto" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
						<Box mb={4}>
							<Typography fontSize={40} fontWeight="bold" letterSpacing={1} color="primary.main" align="center">
								ADMIN LOGIN
							</Typography>
							<Typography fontSize={12} color="gray" align="center" letterSpacing={1}>
								SPYWARE COMPUTERS
							</Typography>
						</Box>
						<Paper sx={{ width: "100%", padding: 2, pb: 5 }}>
							<Typography fontSize={25} fontWeight="bold" align="center" mb={2}>
								Welcome
							</Typography>
							<TextField fullWidth variant="standard" type="email" label="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
							<TextField fullWidth variant="standard" type="password" label="Password" sx={{ mt: 3 }} value={password} onChange={(event) => setPassword(event.target.value)} />
							<Box textAlign="right">
								<Button color="primary" onClick={() => navi("/reset-admin")}>
									Forget your password?
								</Button>
							</Box>
							<Box mt={3} textAlign="center">
								<Button variant="contained" color="primary" sx={{ width: "100%" }} onClick={() => login()}>
									Login
								</Button>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

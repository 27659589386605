import { CloudUpload } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import axios from "axios";

export default function HeroSliderAdmin({ snackbar }) {
	const apiUrl = process.env.REACT_APP_ADMIN_V2;
	const [action, setAction] = useState("");

	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "absolute",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	});

	const [sliders, setSliders] = useState([]);

	useEffect(() => {
		get_hero_slider();
	}, []);
	const get_hero_slider = () => {
		axios.get(apiUrl + "?action=get-hero-slider").then((res) => {
			if (res.data.status === true) {
				setSliders(res.data.data);
			} else {
				setSliders(res.data);
			}
		});
	};

	const add_slider = (event) => {
		setAction("uploading");
		let formData = new FormData();
		formData.append("img", event.target.files[0]);
		axios.post(apiUrl + "?action=add-hero-slider", formData).then((res) => {
			setAction("");
			snackbar(res.data.msg);
			if (res.data.status === true) {
				get_hero_slider();
			}
		});
	};

	const delete_slider = (sliderId, img) => {
		setAction("deleting");
		let formData = new FormData();
		formData.append("sliderId", sliderId);
		formData.append("img", img);
		axios.post(apiUrl + "?action=delete-hero-slider", formData).then((res) => {
			setAction("");
			snackbar(res.data.msg);
			if (res.data.status === true) {
				get_hero_slider();
			}
		});
	};

	return (
		<Box className="content">
			<Container>
				<Box>
					<Button color="info" fullWidth size="large" component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={action === "uploading" ? <CircularProgress size={20} color="inherit" /> : <CloudUpload />} disabled={action === "uploading" ? true : false} onChange={(event) => add_slider(event)}>
						Upload a slide
						<VisuallyHiddenInput type="file" onChange={(event) => console.log(event.target.files)} />
					</Button>
					<Typography variant="caption" color={"gray"}>
						*For website performance please upload only 3 images.
					</Typography>

					{sliders.status === false ? (
						<Alert severity="info" sx={{ mt: 2 }}>
							Please upload hero sliders
						</Alert>
					) : (
						sliders.map((row, i) => (
							<Stack key={i} spacing={2} sx={{ mt: 2 }} direction={"column"}>
								<img src={`/spywareassets/gallery/${row.img}`} style={{ borderRadius: "5px" }} onDoubleClick={() => delete_slider(row.slider_id, row.img)} />
							</Stack>
						))
					)}
				</Box>
			</Container>
		</Box>
	);
}
